/*------- common style css start -------*/

/*-------- Custom Checkbox Style start --------*/
.custom-checkbox,
.custom-radio {

    &.custom-control {
        min-height: auto;
    }

    label,
    .custom-control-label {
        font-size: 14px;
        line-height: 1;
        padding-left: 10px;
        font-weight: 400;
        display: block;
        cursor: pointer;

        &:before {
            background-color: transparent;
            border: 1px solid #ddd;
            border-radius: 0;
            height: 15px;
            top: 0;
            width: 15px;
        }

        &:after {
            background-size: 10px 10px;
            height: 15px;
            top: 0;
        }

        a {
            color: $black-soft;
            font-weight: 500;

            &:hover {
                color: $theme-color;
            }
        }
    }

    input,
    .custom-control-input {
        outline: none;

        &:focus {

            ~.custom-control-label,
            label {
                &:before {
                    box-shadow: none;
                }
            }
        }

        &:checked {

            ~.custom-control-label,
            label {
                &:before {
                    background-color: $theme-color;
                    border-color: $theme-color;
                }
            }
        }
    }
}
/*-------- Custom Checkbox Style end --------*/

/*------- Custom Radio Button Style start -------*/
.custom-radio {
    label,
    .custom-control-label {
        &:before {
            border-radius: 50%;
            height: 14px;
            width: 14px;
        }

        &::after {
            background-size: 10px 10px;
            left: -22px;
            top: 2px;
            height: 10px;
            width: 10px;
        }
    }
}
/*------- Custom Radio Button Style end -------*/

.bg-gray {
    background-color: #f4f4f4;
}

/*------ twitter feed start ------*/
.twitter-feed-content {
    padding: 32px 0;
    border-bottom: 1px solid $bdr-color;
    p {
        color: #888;
        a {
            color: $theme-color;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
/*------ twitter feed end ------*/

/*----- product tab menu start -----*/
.product-tab-menu {
    margin-top: -6px;
    margin-bottom: 35px;
    ul {
        li {
            margin-right: 35px;
            // responsive
            @media #{$xxs-device} {
                margin-right: 15px;
            }
            &:last-child {
                margin-right: 0;
            }
            a {
                font-size: 16px;
                font-weight: 400;
                line-height: 1.2;
                color: $paragraph-light; 
                &:hover,
                &.active {
                    color: $black-soft;
                }
            }
        }
    }
}

// tab animation
.tab-content {
    .tab-pane {
        &.show {
            &.active {
                .product-item {
                    animation-name: zoomIn;
                    animation-duration: 1s;
                }
            }
        }
    } 
}

/*----- product tab menu start -----*/

/*----- brand logo style start -----*/
.brand-logo-carousel {
    padding: 30px 0;
    border-top: 1px solid $bdr-color;
    border-bottom: 1px solid $bdr-color;
}
.brand-item {
    img {
        margin: auto;
    }
}
/*----- brand logo style end -----*/

/*----- hot deals item start -----*/
.hot-deals-item {
    border: 1px solid $theme-color;
    border-radius: 5px;
    .product-caption {
        padding: 20px;
        // responsive
        @media #{$sm-device} {
            padding: 20px 10px;
        }
    }
    .manufacturer-name {
        padding-bottom: 5px;
    }
    .product-name {
        font-size: 20px;
        padding-bottom: 30px;
    }
    .price-box {
        font-size: 21px;
        padding-top: 8px;
    }
}
// quantity progress bar
.progress {
    height: 15px;
    border-radius: 50px;
    &-quantity {
        position: relative;
        margin-top: 52px;
    }
    &-bar {
        background-color: $theme-color !important;
        width: 60%;
        &:nth-child(2) {
            width: 70%;
        }
    }
    &-title {
        display: flex;
        position: absolute;
        top: calc(-100% - 8px);
        left: 0;
        width: 100%;
        line-height: 1;
        justify-content: space-between;
    }
}
.product-sold,
.product-available {
    font-size: 14px;
    color: $paragraph-light;
    span {
        color: $theme-color;
        font-weight: 700;
    }
}

// product countdown
.product-countdown {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    .single-countdown {
        height: 45px;
        width: 45px;
        text-align: center;
        line-height: 45px;
        margin-right: 10px;
        border-radius: 50%;
        background-color: #eaeaea;

        &__time {
            font-size: 16px;
            display: inline-block;
            color: $black-soft;
        }

        &__text {
            font-size: 11px;
            display: block;
            color: $black-soft;
            line-height: 1;
            margin-top: 5px;
            text-transform: uppercase;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    &--style-two {
        @media #{$sm-device}{
            justify-content: flex-start;
        }
    }
}

.product-details-des {
    .product-countdown {
        margin: 20px 0 30px;
        display: flex;
        justify-content: flex-start;
    }
}
/*----- hot deals item start -----*/

/*------ Instagram Feed Area Start ------*/
.instagram-item {
    position: relative;
    z-index: 1;

    &:before {
        background-color: rgba($black, 0.4);
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        transition: 0.4s;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        height: 100%;
        width: 100%;
    }

    .instagram-hvr-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transition: 0.4s;
        opacity: 0;
        visibility: hidden;
        transform: translate(-50%, -50%);
        z-index: 1;

        span {
            color: $white;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }

            i.fa {
                margin-right: 5px;
            }
        }
    }

    &:hover {
        &:before {
            opacity: 1;
            visibility: visible;
        }

        .instagram-hvr-content {
            opacity: 1;
            visibility: visible;
        }
    }
}
/*------ Instagram Feed Area End ------*/

/*------- common style css end -------*/