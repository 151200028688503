/*------ hero slider area css start ------*/
/*-------------------------
02. Slider area
--------------------------*/
.hero-slider-item {
    height: 530px;
    display: flex;
    align-items: center;
    // responsive
    @media #{$md-device, $sm-device} {
        height: 400px;
    }
    @media #{$xs-device} {
        height: 350px;
    }
}
.hero-style-five {
    .hero-slider-item {
        height: 800px;
        // responsive
        @media #{$xlmax-device, $lg-device}{
            height: 530px;
        }
        @media #{$md-device, $sm-device} {
            height: 400px;
        }
        @media #{$xs-device} {
            height: 350px;
        }
    }
}
.hero-slider-content {
    // responsive
    @media #{$sm-device} {
        padding-right: 150px;
    }
    @media #{$xs-device} {
        padding-right: 90px;
    }
    @media #{$xxs-device} {
        padding-right: 0;
    }
    .slide {
        &-title{
            font-size: 60px;
            font-weight: normal;
            line-height: 1;
            span {
                display: block;
            }
            // responsive
            @media #{$md-device} {
                font-size: 50px;
            }
            @media #{$sm-device} {
                font-size: 40px;
                line-height: 1.2;
            }
            @media #{$xs-device} {
                font-size: 35px;
            }
            @media #{$xxs-device} {
                font-size: 30px;
            }
        }  
        &-desc {
            font-size: 20px;
            font-weight: 300;
            line-height: 1.3;
            color: $paragraph;
            padding-top: 20px;
            @media #{$xs-device} {
                padding-top: 10px;
            }
        }
    }
}

.btn-hero {
    color: $white;
    font-size: 15px;
    line-height: 1;
    padding: 14px 30px;
    display: inline-block;
    border-radius: 50px;
    background-color: $theme-color;
    margin-top: 38px;
    // responsive
    @media #{$xs-device} {
        margin-top: 25px;
    }
    &:hover {
        color: $white;
        background-color: $black-soft;
    }
}

.hero-overlay {
    position: relative; 
    &:before {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        background-color: rgba($white, 0.7);
        display: none;
        // responsive
        @media #{$sm-device} {
            display: block;
        }
    }
}
/*------ hero slider area css end ------*/

// slider text animation
.slick-active {
    .hero-slider-content {
        &.slide-1 {
            h2 {
                animation: bounceIn 2s linear alternate;
            }
            h4 {
                animation: zoomIn 1s linear alternate;
            }
            a {
                animation: slideInUp 0.5s linear alternate; 
            }
        } 
        &.slide-2 {
            h2 {
                animation: bounceIn 1.5s linear alternate;
            }
            h4 {
                animation: fadeInLeft 1s linear alternate;
            }
            a {
                animation: slideInUp 0.5s linear alternate; 
            }
        }
        &.slide-3 {
            h2 {
                animation: fadeInUp 1000ms linear alternate;
            }
            h4 {
                animation: bounceIn 600ms linear alternate;
            }
            a {
                animation: fadeInUp 1400ms linear alternate; 
            }
        }
    }
}
